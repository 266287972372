* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
    height: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

.dropzoneCustomize {
    padding: 20px;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    .MuiChip-root {
        margin-top: 20px;
        min-height: 32px;
        height: auto;
        background-color: white;
        padding: 5px;
        border-radius: 5px;
        .MuiChip-label {
            line-height: 150%;
            white-space: normal;
            word-break: break-all;
        }
    }
}

.dropzoneCustomize + .MuiSnackbar-root {
    white-space: normal;
    word-break: break-all;
}

.dropzoneCustomize[class*="DropzoneArea-rejectStripes"] {
    border-color: #d2d2d2!important;
    background-image: repeating-linear-gradient(-45deg, #f3f3f3, #f3f3f3 25px, #d2d2d2 25px, #d2d2d2 50px)!important;
}
